import { currentVersion } from './../app/shared/version-number/version';
export const environment = {
  production: true,
  baseUrl: window.location.origin + '/Dev/radial',
  rucUrl: window.location.origin + '/Dev/ruc',
  generateReportUrl:
    window.location.origin +
    '/generatereport/l225g5o4ng7xvykf35vtpibxra0wxryd.lambda-url.eu-west-1.on.aws',
  pingUrl: window.location.origin + '/Dev/ping/userinfo',
  qlikViewGraphUrl: window.location.origin + '/Dev/radial/clickview',
  cbrGraphUrl: window.location.origin + '/Dev/radial/clientbaselinereports',
  clientConfigUrl: window.location.origin + '/Dev/radial/clientconfig/',
  userConfigUrl: window.location.origin + '/Dev/radial/userconfig/',
  logout: 'https://login.bpost.be/idp/startSLO.ping',
  VERSION: currentVersion,
  googleAnalyticsMeasurementId: 'G-4G1VE1QGMY',
  reportUrl: 'https://datawarehouse-report.s3.eu-west-1.amazonaws.com/',
  rootUrl: window.location.origin,
  DEPLOYED_BRANCH_NAME: '',
  DEPLOYED_BRANCH_URL: '',
};
