export const CpRoutes = {
  RealtimeDashboard: 'reporting/realtime-dashboard',
  HistoricDashboard: 'reporting/historic-dashboard',
  ReportBuilder: 'reporting/report-builder',
  PurchaseOrderDetails: 'inbound/po-details',
  PurchaseOrderDetailsInfo: 'inbound/po-details/info',
  PurchaseOrderDetailsGraph: 'inbound/po-details/graph',
  OrderDetails: 'outbound/order-details',
  OrderDetailsGraph: 'outbound/order-details/graph',
  OrderDetailsInfo: 'outbound/order-details/info',
  OrdersOnHold: 'outbound/orders-on-hold',
  Kitting: 'outbound/kitting',
  KittingOrderDetail: 'outbound/kitting/kitting-order-details',
  KittingOrdersImport: 'outbound/kitting/import-kitting-orders',
  Forecast: 'forecast',
  SitePlanningForecast: 'forecast/site-planning',
  VolumeForecast: 'forecast/volume-planning',
  PeakForecast: 'forecast/peak-planning',
  ImportForecastData: 'forecast/import-forecast-data',
  OrdersOnHoldInfo: 'outbound/orders-on-hold/order-details',
  OrdersOnHoldAmendOrder: 'outbound/orders-on-hold/amend-order',
  OntimeShipSLA: 'outbound/on-time-ship-sla',
  OntimeShipSLAGraph: 'outbound/on-time-ship-sla/graph',
  OntimeShipSLAList: 'outbound/on-time-ship-sla/list',
  ReturnDetails: 'return/return-details',
  ReturnDetailsGraph: 'return/return-details/graph',
  InventoryDetails: 'inventory/inventory-details',
  InventoryDetailsInfo: 'inventory/inventory-details/info',
  Parameter: 'config/parameter',
  UserConfig: 'config/user-config',
  ClientConfig: 'config/client-config',
  ChangeAddress: 'outbound/order-details/change-address',
  OrderCancel: null,
  TrackandTrace: null,
  AddItem: null,
  EditItem: null,
  CancelItem: null,
  DownloadCommercialInvoices: null,
  ProductCategory: null,
};
